import { Component } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import {
  ExportExcel,
  SButtom,
  SHr,
  SImage,
  SLoad,
  SNavigation,
  SOrdenador,
  SPage,
  SScrollView2,
  SText,
  STheme,
  SView
} from "servisofts-component";
import SSocket from "servisofts-socket";
import Usuario from "../..";
import BarraSuperior from "../../../../Components/BarraSuperior";
import Buscador from "../../../../Components/Buscador";
import FloatButtom from "../../../../Components/FloatButtom";
import FloatButtomSyncUsuario from "../../../../Components/FloatButtomSyncUsuario";
import Model from "../../../../Model";
import { SSRolesPermisosValidate } from "../../../../SSRolesPermisos";

var objFinal = {};
let tamaño_texto = 18;

class VentasPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        curPage: 1,
      },
    };
    this.onSelect = SNavigation.getParam("onSelect")
  }
  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
    // var object = {
    //   service: "usuario",
    //   component: "usuario",
    //   version: "2.0",
    //   type: "getAll",
    //   estado: "cargando",
    //   cabecera: "registro_administrador",
    //   key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
    // }
    // SSocket.send(object);
  }
  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 50;
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  };
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />;
    }
    if (data.estado != 0) {
      return <View />;
    }
    return (
      <SButtom
        props={{
          type: "danger",
          variant: "confirm",
        }}
        onPress={() => {
          Usuario.Actions.editar(
            {
              ...data,
              estado: 1,
            },
            this.props
          );
        }}
      >
        Recuperar
      </SButtom>
    );
  }

  // valido_CI(ci) {
  //   // valido si es null el parametro ci
  //   return (
  //     <Text style={{ color: (ci ?? "").length < 7 ? "red" : STheme.color.text }}>
  //       {"CI: " + (ci ?? "")}
  //     </Text>
  //   );
  // }
  valido_Telefono11111111(telefono) {
    // valido si es null el parametro telefono
    return (
      <Text
        style={{
          color:
            (telefono ?? "").length < 8 ||
              ((telefono ?? "").charAt(0) !== "7" &&
                (telefono ?? "").charAt(0) !== "6" &&
                (telefono ?? "").charAt(0) !== "+")
              ? "red"
              : STheme.color.text,
        }}
      >
        {"Telefono: " + (telefono ?? "")}
      </Text>
    );
  }
  // valido_Correo(correo) {
  //   // valido si es null el parametro correo
  //   return (
  //     <Text style={{ color: (correo ?? "").length < 12 ? "red" : STheme.color.text }}>
  //       {"Correo: " + (correo ?? "")}
  //     </Text>
  //   );
  // }
  // valido_Cumpleaños(Cumpleaños) {
  //   // valido si es null el parametro Cumpleaños
  //   return (
  //     <Text style={{ color: (Cumpleaños ?? "").length < 10 ? "red" : STheme.color.text }}>
  //       {"Cumpleaños: " + (Cumpleaños ?? "")}
  //     </Text>
  //   );
  // }


  // valido_Cumpleaños2(Cumpleaños) {
  //   var fecha = Cumpleaños;
  //   var fechaObj = new Date(fecha);
  //   var mes = fechaObj.getMonth() + 1;

  //   const fechaActual = new Date();
  //   const mesActual = fechaActual.getMonth() + 1;

  //   var mensaje = "";
  //   if (mes === mesActual) {
  //     mensaje = "Cumpleañero 🥳📆 " + (fecha ?? "");
  //   } else {
  //     mensaje = "Fecha nacimiento: " + (fecha ?? "");
  //   }
  //   const textColor = (Cumpleaños === null ? "red" : (mes === mesActual ? "green" : STheme.color.text));
  //   return <Text style={{ color: textColor }}>{mensaje}</Text>;
  // }

  // valido_Zona(zona) {
  //   // if (!zona) return;
  //   // valido si es null el parametro zona
  //   return (
  //     <Text style={{ color: (zona ?? "").length < 3 ? "red" : STheme.color.text }}>
  //       {"Zona: " + (zona ?? "")}
  //     </Text>
  //   );
  // }

  // valido_Direccion(Direccion) {
  //   // if (!Direccion) return;
  //   // valido si es null el parametro zona
  //   return (
  //     <Text style={{ color: (Direccion ?? "").length < 5 ? "red" : STheme.color.text }}>
  //       {"Dirección: " + (Direccion ?? "")}
  //     </Text>
  //   );
  // }

  // valido_Empresa(empresa) {
  //   if (!empresa) return;
  //   // valido si es null el parametro empresa
  //   return (
  //     <Text style={{ color: (empresa ?? "").length < 3 ? "red" : STheme.color.text }}>
  //       {"Empresa: " + (empresa ?? "")}
  //     </Text>
  //   );
  // }
  // valido_Facturacion(nombre) {
  //   if (!nombre) return;
  //   // valido si es null el parametro empresa
  //   return (<>
  //     <SView border={"yellow"} style={{ padding: 1, position: "absolute", top: -6 }}>
  //       <Text style={{ color: STheme.color.text }}>
  //         {"FACTURACIÓN"}
  //       </Text>
  //     </SView>
  //     <SHr></SHr>
  //   </>
  //   );
  // }

  // valido_RazonSocial(nombre) {
  //   // if (!nombre) return;
  //   // valido si es null el parametro empresa
  //   return (
  //     <Text style={{ color: (nombre ?? "").length < 2 ? "red" : "cyan" }}>
  //       {"Razón Social: " + (nombre ?? "")}
  //     </Text>
  //   );
  // }

  // valido_Nit(nit) {


  //   if ((nit ?? "").length > 9) {
  //     return (
  //       <Text style={{ color: (nit ?? "").length < 2 ? "red" : "cyan" }}>
  //         {"Nit: " + (nit ?? "")}
  //       </Text>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <SText color={STheme.color.text} >Nit:</SText>
  //         <SText color={"red"} >{nit ?? " Error"}</SText>

  //       </>
  //     );
  //   }
  // }

  // valido_Cargo(cargo) {
  //   if (!cargo) return;
  //   // valido si es null el parametro cargo
  //   return (
  //     <Text style={{ color: (cargo ?? "").length < 3 ? "red" : STheme.color.text }}>
  //       {"Cargo: " + (cargo ?? "")}
  //     </Text>
  //   );
  // }
  // valido_Referencia(referencia) {
  //   if (!referencia) return;
  //   // valido si es null el parametro cargo
  //   return (
  //     <Text style={{ color: (referencia ?? "").length < 5 ? "red" : STheme.color.text }}>
  //       {"Referencia: " + (referencia ?? "")}
  //     </Text>
  //   );
  // }


  template() {
    let isMobile = window.innerWidth <= 768; // Definir el tamaño_texto máximo para considerar como pantalla pequeña
    tamaño_texto = isMobile ? 6.5 : 9;
    if (isMobile) return null;

    return (
      <>
        <SView
          col={"xs-2.5 md-2.4"}
          border={"yellow"}
          style={{
            position: "absolute",
            top: 80,
            borderRadius: 8,
            padding: 5,
            fontSize: 2,
          }}
        >
          <SText bold color={"yellow"}>
            Nota:
          </SText>
          <SHr></SHr>
          <SText>
            Antes de proceder con el registro de una venta, es fundamental
            revisar los siguientes criterios de búsqueda:
          </SText>
          <SHr></SHr>
          <SText>1.- Documento (CI)</SText>
          <SText>2.- Nombres completo</SText>
          <SText>3.- Teléfono</SText>
          <SHr></SHr>
          <SText>
            Es bolivia de suma importancia asegurarse de que los datos sean precisos, ya
            que se utilizarán para verificar la autenticidad de la información
            al realizar
            <SText color={"yellow"}> sorteos </SText>o
            <SText color={"yellow"}>entregar permisos.</SText>
          </SText>
        </SView>
      </>
    );
  }

  exportar_excel() {
    if (!SSRolesPermisosValidate({ page: "ClientesPage", permiso: "exportar_excel" })) { return; }

    return (
      <ExportExcel
        header={[
          // { key: "indice", label: "n", width: 40 },
          { key: "key_usuario", label: "key", width: 220 },
          { key: "nombres", label: "Nombres", width: 200 },

          { key: "ci", label: "Documento", width: 60 },
          { key: "telefono", label: "Telefono", width: 90 },
          { key: "correo", label: "Correo", width: 170 },

          { key: "cumpleaños", label: "Cumpleaños", width: 75 },
          { key: "zona", label: "Zona", width: 150 },
          { key: "direccion", label: "Dirección", width: 170 },

          { key: "empresa", label: "Empresa", width: 150 },
          { key: "cargo", label: "Cargo", width: 150 },
          { key: "referencia", label: "Referencia", width: 100 },
        ]}
        getDataProcesada={() => {
          var daFinal = {};
          Object.values(objFinal).map((obj, i) => {

            if (obj.estado == 0) return;
            var toInsert = {
              indice: i + 1,
              key_usuario: obj?.key,
              nombres: obj?.Nombres + " " + obj?.Apellidos,

              ci: obj?.CI,
              telefono: obj?.Telefono,
              correo: obj?.Correo,

              cumpleaños: obj["Fecha nacimiento"],
              zona: obj?.Zona,
              direccion: obj?.Direccion,

              empresa: obj?.Empresa,
              cargo: obj?.Cargo,
              referencia: obj?.Referencia,
            };
            daFinal[i] = toInsert;
          });
          return daFinal;
        }}
      />
    );
  }

  valido_NombreCompleto(nombre, apellidos) {
    return <SText fontSize={tamaño_texto + 6} style={{ color: ((nombre ?? "").length < 3 || (apellidos ?? "").length < 3) ? "red" : STheme.color.text }}>{(nombre ?? "") + " " + (apellidos ?? "")}</SText>;
  }

  valido_CI(nit) {
    return <>
      <SText fontSize={tamaño_texto} color={(nit ?? "").length < 5 ? STheme.color.text : STheme.color.text} >{"CI: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 5 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }

  valido_Cumpleaños(Cumpleaños) {
    return <>
      <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"F.Nac: " + (Cumpleaños ?? "")}   </SText>
      {(Cumpleaños ?? "").length < 9 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }
  valido_sexo(Cumpleaños) {
    return <>
      <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Género: " + (Cumpleaños ?? "")}  </SText>
      {(Cumpleaños ?? "").length < 2 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }

  valido_Festivo(Cumpleaños) {
    return <>
      <SText fontSize={tamaño_texto + 2} color={STheme.color.text} >{"Festivo: " + (Cumpleaños ?? "")}</SText>
      {(Cumpleaños ?? "").length < 2 ? <SText fontSize={tamaño_texto + 2} backgroundColor='#C70039' color="white" bold border={5} > error </SText> : ""}
    </>
  }

  valido_Telefono(telefono) {
    return <>
      <SText fontSize={tamaño_texto} color={STheme.color.text} >{"Telefono: " + (telefono ?? "")}      </SText>
      {(telefono ?? "").length < 8 || ((telefono ?? "").charAt(0) !== "7" && (telefono ?? "").charAt(0) !== "6" && (telefono ?? "").charAt(0) !== "+") ? <SText fontSize={tamaño_texto} backgroundColor='red' color="white" bold > error </SText> : ""}
    </>
  }

  valido_Correo(correo) {
    return <SText fontSize={tamaño_texto} style={{ color: (correo ?? "").length < 12 ? "red" : STheme.color.text }}> {"Correo: " + (correo ?? "")} </SText >;
  }

  valido_Nit(nit) {
    return <>
      <SText fontSize={tamaño_texto} color={(nit ?? "").length < 7 ? STheme.color.text : STheme.color.text} >{"Nit: " + (nit ?? "")}   </SText>
      {(nit ?? "").length < 7 ? <SText fontSize={tamaño_texto + 2} backgroundColor='red' color={STheme.color.text} bold > error </SText> : ""}
    </>
  }

  valido_RazonSocial(nombre) {
    return <>
      <SText fontSize={tamaño_texto} color={(nombre ?? "").length < 5 ? STheme.color.text : STheme.color.text} >{"Razón Social: " + (nombre ?? "")}   </SText>
      {(nombre ?? "").length < 5 ? <SText fontSize={tamaño_texto} backgroundColor='red' color={STheme.color.text} bold > error </SText> : ""}
    </>
  }







  render() {
    const getLista = () => {
      var data = Model.usuario.Action.getAll();
      // var dataRU = SSRolesPermisos.Events.getUsuarioRol("d16d800e-5b8d-48ae-8fcb-99392abdf61f", this.props)
      var dataRU = Model.usuarioRol.Action.getAllByKeyRol(
        "d16d800e-5b8d-48ae-8fcb-99392abdf61f"
      );
      if (!data) return <SLoad />;
      if (!dataRU) return <SLoad />;
      if (!this.state.buscador) {
        return <View />;
      }
      Object.keys(dataRU).map((key) => {
        var rol_user = dataRU[key];
        if (!rol_user.key_usuario) return;
        if (!rol_user.estado) return;
        if (!data[rol_user.key_usuario]) {
          return;
        }
        if (!data[rol_user.key_usuario].estado) return;
        objFinal[rol_user.key_usuario] = data[rol_user.key_usuario];
      });
      var isRecuperar = SSRolesPermisosValidate({
        page: "UsuarioPage",
        permiso: "recuperar_eliminado",
      });
      return this.pagination(
        new SOrdenador([
          { key: "Peso", order: "desc", peso: 4 },
          { key: "Nombres", order: "asc", peso: 2 },
          { key: "Apellidos", order: "asc", peso: 1 },
        ]).ordernarObject(this.state.buscador.buscar(objFinal))
      ).map((key) => {
        var obj = data[key];
        return (
          <TouchableOpacity
            style={{
              width: "90%",
              maxWidth: 600,
              height: 65,
              margin: 4,
              borderRadius: 10,
              backgroundColor: STheme.color.card,
            }}
            onPress={() => {

              if (this.onSelect) {
                this.onSelect(obj);
                return;
              }// return;
              this.select = SNavigation.getParam("select");
              if (this.select) {
                this.select(obj);
                SNavigation.goBack();
                return;
              }
              SNavigation.navigate("ClientePerfilPage", {
                key: key,
              });
            }} >
            <View style={{ flex: 1, justifyContent: "center" }} >
              <View
                style={{
                  flexDirection: "row",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <SView border={"yellow"}
                  style={{ width: 40, height: 40, marginRight: 8, marginLeft: 8, justifyContent: "center", alignItems: "center", backgroundColor: STheme.color.card, borderRadius: 100, overflow: "hidden" }}
                >
                  <SImage src={SSocket.api.root + "usuario/" + key + `?date=${new Date().getTime() / 500}`}
                  />
                </SView>

                <View style={{ flex: 1, justifyContent: "center" }}>
                  <SView col={"xs-12"} row height  >
                    <SView col={"xs-8"} border={"transparent"} >
                      <SText fontSize={tamaño_texto} color={STheme.color.text}> {this.valido_NombreCompleto(obj?.Nombres, obj?.Apellidos)} </SText>
                      {/* <SText fontSize={tamaño_texto} color={STheme.color.text}> {this.valido_CI(obj?.CI)} </SText> */}
                      <SView col={"xs-12"} border={"transparent"} row>{this.valido_CI(obj?.CI)}{this.valido_Cumpleaños(obj?.["Fecha nacimiento"])}{this.valido_sexo(obj?.Sexo)}{this.valido_Festivo(obj?.Festivo)}</SView>



                      {/* <SView col={"xs-12"} border={"transparent"} row>{this.valido_NombreCompleto(obj?.Nombres, obj?.Apellidos)}{this.valido_Cumpleaños(obj?.["Fecha nacimiento"])} </SView> */}
                      <SView col={"xs-12"} border={"transparent"} row >{this.valido_Telefono(obj?.Telefono)} {this.valido_Correo(obj?.Correo)}</SView>
                    </SView>
                    <SView col={"xs-4"} border={"transparent"} >
                      <SText fontSize={tamaño_texto} color={"cyan"}>DATOS FACTURACIÓN</SText>
                      <SText fontSize={tamaño_texto} color={STheme.color.text}>{this.valido_Nit(obj?.Nit)}</SText>
                      <SText fontSize={tamaño_texto} color={STheme.color.text}>{this.valido_RazonSocial(obj?.RazonSocial)}</SText>
                    </SView>
                  </SView>
                </View>
                {this.getRecuperar(obj, isRecuperar)}
              </View>
            </View>
          </TouchableOpacity>
        );
      });
    };

    return (
      <>
        <SPage hidden disableScroll>
          <BarraSuperior
            title={"Ventas"}
            navigation={this.props.navigation}
            goBack={() => {
              SNavigation.goBack();
            }}
          />
          <Buscador
            placeholder={
              "Buscar por CI, Nombres, Apellidos, Correo o Telefono."
            }
            ref={(ref) => {
              if (!this.state.buscador) this.setState({ buscador: ref });
            }}
            repaint={() => {
              this.setState({ ...this.state });
            }}
          />
          <View
            style={{
              flex: 1,
              width: "100%",
            }}
          >
            <SScrollView2
              disableHorizontal
              onScroll={(evt) => {
                var evn = evt.nativeEvent;
                var posy = evn.contentOffset.y + evn.layoutMeasurement.height;
                var heigth = evn.contentSize.height;
                if (heigth - posy <= 0) {
                  this.state.pagination.curPage += 1;
                  this.setState({ ...this.state });
                }
              }}
            >
              <SView col={"xs-12"} center>
                {this.exportar_excel()}
              </SView>

              <SView col={"xs-12"} center>
                {getLista()}
              </SView>
            </SScrollView2>
            <FloatButtomSyncUsuario style={{ bottom: 90, }}
              onPress={() => {
                Model.usuario.Action.CLEAR_STORAGE()
              }}
            />
            <FloatButtom
              onPress={() => {
                SNavigation.navigate("registro");
              }}
            />
            {/* {/* <SButtom style={{ position: "absolute", top: 5, right: 50, backgroundColor: "salmon", borderRadius: 4 }} onPress={() => { SNavigation.navigate("clientes/historial"); }}>Historial</SButtom> */}
            {/* <SButtom type={"danger"} style={{ position: "absolute", top: 70, right: 50, backgroundColor: "#D68910" }} onPress={() => { SNavigation.navigate("clientes/comparacion"); }}>Comparación</SButtom> */}
            {/* <SButtom style={{ position: "absolute", top: 140, right: 50, backgroundColor: "#1D8348", borderRadius: 4 }} onPress={() => { SNavigation.navigate("clientes/cumplenos"); }}>Cumpleaños📆</SButtom> */}
            {/* <SView style={{ position: "absolute", bottom: 90, right: 10, width: 50 }} onPress={() => { Model.usuario.Action.CLEAR_STORAGE() }}><SIcon name="SyncUsuario"></SIcon></SView> */}
          </View>
        </SPage>
        {this.template()}
      </>
    );
  }
}
const initStates = (state) => {
  return { state };
};
export default connect(initStates)(VentasPage);
