import { Component } from 'react'
import { SHr, SIcon, SNavigation, SPage, SPopup, SText, SView } from 'servisofts-component'
import { MenuButtom, MenuPages } from 'servisofts-rn-roles_permisos'
export default class root extends Component {


  getItemFecha({ title, icon, url, onPress, permiso }) {
    return this.getItem({
      title: title, icon: icon, onPress: () => {
        SPopup.dateBetween(title, (evt) => {
          // alert(JSON.stringify(evt));
          SNavigation.navigate(url, evt);
        });
      }
    })
  }


  getItem({ title, icon, url, onPress, permiso }) {
    return <SView col={"xs-3 sm-2.5 md-2 lg-1.5 xl-1.3"} colSquare style={{ padding: 4, }}
    >
      <SView col={"xs-12"} height center>
        <SView col={"xs-7"} colSquare onPress={() => {
          if (onPress) {
            onPress();
            return;
          }
          SNavigation.navigate(url);
        }} >


          <SIcon name={icon} />
        </SView>
        <SHr />
        <SView center height={16}>
          <SText center fontSize={12}>{title}</SText>
        </SView>
      </SView>
    </SView>
  }

  render() {
    return <SPage title={"Facturacion"}>
      <MenuPages path={"/facturacion/"} permiso={"page"}
      // filter={(obj) => !!obj.is_page}
      >
        {/* <MenuButtom label={"Notificaciones"} url={"/notificacion"} icon={""} /> */}
        <MenuButtom label={"Parametricas"} url={"/facturacion/parametricas"} icon={<SIcon name='Ajustes' />} />
        {/* <MenuButtom label={"Facturas"} url={"/facturacion/facturas"} icon={<SIcon name='Ajustes' />} /> */}
        <MenuButtom label={"Reporte Excel"} url={"/facturacion/reporte_excel"} icon={<SIcon name='Excel' />} />
        <MenuButtom label={"DasBoard"} url={"/facturacion/dashboard"} icon={<SIcon name='Alert' />} />
        {this.getItemFecha({ title: "Facturas", icon: "Pruebas", url: "/facturacion/facturas", permiso: "PaquetesVendidos" })}

      </MenuPages>

    </SPage>
  }



}