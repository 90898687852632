import { SPage } from "servisofts-component";
import root from "./root";
import parametricas from "./parametricas"
import parametricas_table from "./parametricas_table"
import facturas from "./facturas"
import reporte_excel from "./reporte_excel"
import dashboard from "./dashboard"




export default SPage.combinePages("facturacion", {
    "": root,
    parametricas,
    "parametricas/table": parametricas_table,
    facturas,
    reporte_excel,
    dashboard
})

