import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as xlsx from 'xlsx';
import { SHr, SIcon, SInput, SPage, SText } from 'servisofts-component';
import Botoncito from '../../Components/Botoncito/index';

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleOnPress = () => {

    }
    render() {
        return (
            <SPage title={'Test'}>
                <SHr />
                <Botoncito onPress={this.handleOnPress} ><SIcon name='Ajustes' /></Botoncito>
                <SHr />
                <Botoncito type='alvaro' onPress={this.handleOnPress} >{"Typo alvaro"}</Botoncito>
                <SHr />
                <Botoncito type='ricky' onPress={this.handleOnPress} style={{
                    position: "absolute",
                }}>{"Tipo ricky"}</Botoncito>
                <SHr />

            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Test);



/* <SInput type={"file"} customStyle={"calistenia"} col={"xs-6"} onChangeText={async (files) => {
                    // files, Es un array de files que contiene 
                    // [{   file:{ },    uri:"..."   }]
                    // xlsx.r

                    const reader = new FileReader();
                    reader.onload = function (e) {
                        const data = new Uint8Array(e.target.result);
                        const workbook = xlsx.read(data, { type: 'array' });
                        console.log(workbook)
                        // process workbook data here
                    };
                    reader.readAsArrayBuffer(files[0].file);

                }} /> */