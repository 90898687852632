import { Component } from "react";
import { connect } from "react-redux";
import SSocket from 'servisofts-socket';
import Model from "../Model";
import { SButtom, SDate, SExcelReader, SHr, SIcon, SInput, SLoad, SNavigation, SPage, SPopup, STable2, SText, STheme, SUuid, SView } from "servisofts-component";
import Paquete from "./Paquete";

let pajarito = '0b29859d-1c59-40f8-a447-c4786af0cef9';

let captura_fecha_inicio = new SDate().setHours(0, 1, 1, 1).toString('yyyy-MM-dd hh:mm:ss');
let captura_fecha_fin = new SDate().addDay(2).setHours(23, 59, 59, 59).toString('yyyy-MM-dd hh:mm:ss');

class testAlvaro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: DataTest
    };
  }


  componentDidMount() {
    SSocket.sendPromise({
      component: "clientesActivos",
      estado: "cargando",
      key_usuario: Model.usuario.Action.getKey(),
      type: "getAll"
    }).then(resp => {
      this.state.clientes_activos = Object.values(resp.data);
      this.setState({ ...this.state })
    })
    Model.usuario.Action.getAll({ force: true });
  }




  renderExcelImport() {

    let paquetes = Paquete.Actions.getAll(this.props);
    // if (!usuarios) return <SLoad />;
    if (!paquetes) return <SLoad />;

    if (this.state.data) return;
    return <SView col={"xs-12"} height padding={8}>

      <SText>Para inscribir a los usuarios en esta promoción de paquete {paquetes[pajarito]?.descripcion} , es necesario cargar un.</SText>
      <SHr />
      <SText>archivo de Excel que incluya las siguientes encabezados.</SText>
      <SHr />
      <SText>{`|  Key_paquete   |  fecha_capturado   |   fecha_inicio    |   fecha_fin   |   key_Usuario |`}</SText>
      <SHr />
      <SText>{`[A1] Key_paquete = Código del paquete`}</SText>
      <SText>{`[B1] fecha = Fecha de registro`}</SText>
      <SText>{`[C1] fecha_inicio = Fecha de inicio de la promoción del paquete`}</SText>
      <SText>{`[D1] fecha_fin  = Fecha de finalización del paquete`}</SText>
      <SText>{`[E1] key_usuario = Código del cliente`}</SText>
      <SHr />
      <SText>Haz clic en el ícono de Excel en la parte inferior derecha para descargar la plantilla de Excel 📊</SText>
      <SHr />

      <SView col={"xs-12"} center>
        <SExcelReader onSubmit={(data, callback) => {
          console.log(data);
          this.setState({ data: data })
          callback();
        }} >
          <SView card padding={30} width={180} center><SText center>SUBIR EXCEL DE DATOS</SText></SView>
        </SExcelReader>
      </SView>
      <SHr />
      <SView flex card>
        <STable2
          header={[
            { key: "key_paquete", label: "📦key_Paquete", width: 150, render: (item) => { return `${paquetes[item]?.descripcion}`; }, component: (e) => <SText center color={"yellow"} >{e}</SText> },
            { key: "fecha_on", label: "F.Registrado", width: 100, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); } },
            { key: "fecha_inicio", label: "🎉F.Incio promo", width: 150, center: true, render: (item) => { return new SDate(item).toString("yyyy-MM-dd hh:mm:ss"); }, component: (e) => <SText center color={"cyan"} >{e}</SText> },
            { key: "fecha_fin", label: "🏁F.Fin promo", width: 150, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy  hh:mm:ss"); }, component: (e) => <SText center color={"cyan"} >{e}</SText> },
            { key: "key_usuario", label: "👤 key_Usuario", width: 200, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; }, component: (e) => <SText center color={"yellow"} >{e}</SText> },
            { key: "Estado", label: "📈Estado", width: 150, component: (e) => <SText center color={"cyan"} >{e = 1 ? "activo" : "inactivo"}</SText> },
          ]}
          data={{}} />
      </SView>
    </SView>
  }


  // handleSubmit(obj) {
  //   obj.user.fecha_inicio = new SDate(obj.fecha_inicio).toString("yyyy-MM-dd");
  //   obj.user.fecha_fin = new SDate(obj.fecha_fin).toString("yyyy-MM-dd");
  //   obj.user.data = {}
  //   let toSend = {
  //     "component": "paqueteVenta",
  //     "type": "registro",
  //     "estado": "cargando",
  //     "key_usuario": Model.usuario.Action.getKey(),
  //     "data": {
  //       "descripcion": "",
  //       "key_paquete": obj.paquete.key,
  //       "monto": obj.paquete.monto ?? 0.00,
  //       "nombre_paquete": obj.paquete.descripcion,
  //       "key": SUuid(),
  //     },
  //     "clientes": [obj.user]
  //   }
  //   this.setState({ loading: true })
  //   SSocket.sendPromise(toSend).then(e => {
  //     e.clientes.map(cli => {
  //       this.state.clientes_activos.push({
  //         ...cli,
  //         paquete: obj.paquete
  //       })
  //     })

  //     this.setState({ loading: false })
  //   }).catch(e => {
  //     this.setState({ loading: false })
  //   })
  // }




  popupEditarFecha(obj) {
    var dia_inicio = new SDate(captura_fecha_inicio).toString("yyyy-MM-dd");
    var dia_fin = new SDate(captura_fecha_fin).toString("yyyy-MM-dd");
    return <>
      <SView width={362} height={325} center row style={{ borderRadius: 8, borderColor: "green" }} withoutFeedback backgroundColor={STheme.color.background}>
        <SHr height={20} />
        <SInput placeholder={"Ingresar f. inicio📅"} col={"xs-8"} type={"date"} defaultValue={dia_inicio} customStyle={"calistenia"}
          // <SInput ref={ref => this._fechaInicio = ref} col={"xs-10"} type={"date"} defaultValue={this.state.parametros.inicio} customStyle={"calistenia"}
          onChangeText={(val) => {
            if (this.state.parametros.inicio != val) {
              dia_inicio = val;
              // this.state.parametros.inicio = val;
              this.setState({ ...this.state })

              // this.calculadorAlvaro(val, this.state.parametros.dias);
            }
          }}
        />





        <SInput placeholder={"Ingresar fecha fin📅"} col={"xs-8"} type={"date"} defaultValue={dia_fin} customStyle={"calistenia"} />

        <SHr height={15} />
        <SText col={"xs-9"} color={STheme.color.text + 66} center>¿Está seguro de que desea cambiar la fecha de la promo?</SText>
        <SButtom type="danger" onPress={() => { SPopup.close("CodigoSeguridad"); }}>Cancelar</SButtom>
        <SView width={30} ></SView>
        <SView width={30} ></SView>
        <SButtom type="success" onPress={() => { }}>Confirmar</SButtom>
        <SHr height={20} />
      </SView >
    </>
  }

  renderTable() {
    if (!this.state.data) return;
    let usuarios = Model.usuario.Action.getAll();
    let paquetes = Paquete.Actions.getAll(this.props);
    if (!usuarios) return <SLoad />;
    if (!paquetes) return <SLoad />;
    if (!this.state.clientes_activos) return <SLoad />;
    let arr_usuarios = Object.values(usuarios);

    // this.state.data.map((obj) => {
    // let user = arr_usuarios.find(a => a.CI == obj.ci);
    // obj.user = user;
    // obj.paquete = paquetes[pajarito]
    // obj.paquete = paquetes[obj.key_paquete]
    // obj.paquete_venta = this.state.clientes_activos.find(ca => ca.key_usuario == user.key && ca.fecha_inicio == new SDate(obj.fecha_inicio).toString("yyyy-MM-dd") && ca.paquete.key == obj.key_paquete)
    // })
    return <STable2
      header={[
        { key: "index", label: "#" },

        {
          key: "-lun", label: "Selec.", width: 45, center: true, component: (obj) => {
            return <SView flex height center >
              <SInput type={"checkBox"}
              // value={this.state._lunes[obj.key]}
              // onChangeText={(e) => {
              //   if (e) {
              //     this.state._totalHoras++;
              //   } else {
              //     this.state._totalHoras--;
              //   }
              //   this.state._lunes[obj.key] = e;
              //   this.setState({ ...this.state })
              // }}
              />
            </SView>
          }
        },

        { key: "key_paquete", label: "📦Paquete", width: 280, render: (item) => { return `${paquetes[pajarito]?.descripcion}`; }, component: (e) => <SText center color={"yellow"} >{e}</SText> },
        // { key: "-precio", label: "💲Precio", width: 60, render: (item) => { return `${paquetes[pajarito]?.precio}`; }, component: (e) => <SText center color={"pink"} >{e}</SText> },
        // { key: "fecha_on", label: "📅F.Registrado", width: 90, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy"); } },
        { key: "fecha_inicio", label: "🎉F.Incio promo", width: 150, center: true, render: (item) => { return new SDate(captura_fecha_inicio).toString("dd-MM-yyyy  hh:mm:ss"); }, component: (e) => <SText center color={"cyan"} >{e}</SText> },
        // { key: "fecha_fin", label: "🏁F.Fin promo", width: 150, center: true, render: (item) => { return new SDate(item).toString("dd-MM-yyyy  hh:mm:ss"); }, component: (e) => <SText center color={"cyan"} >{e}</SText> },
        { key: "fecha_fin", label: "🏁F.Fin promo", width: 150, center: true, render: (item) => { return new SDate(captura_fecha_fin).toString("dd-MM-yyyy  hh:mm:ss"); }, component: (e) => <SText center color={"cyan"} >{e}</SText> },
        { key: "key_usuario", label: "👤 key_Usuario", width: 230, render: (item) => { return `${usuarios[item]?.Nombres} ${usuarios[item]?.Apellidos}`; }, component: (e) => <SText color={"yellow"} >{e}</SText> },
        { key: "-carnet", label: "CI", width: 60, render: (obj) => { return `${usuarios[obj.key_usuario]?.CI}`; } },
        {
          key: "-nacimiento", label: "Cumpleaño", width: 80, render: (obj) => {
            const fechaNacimiento = usuarios[obj.key_usuario]?.["Fecha nacimiento"];
            const validacion = fechaNacimiento ? new SDate(fechaNacimiento).toString('dd/MM') : "";
            const esFechaDeHoy = validacion === new SDate().toString('dd/MM');
            const condicion = esFechaDeHoy ? `${validacion} 🎂` : validacion;
            return condicion;
          }
        },
        { key: "Estado", label: "📊Estado", width: 70, component: (e) => <SText center color={"cyan"} >{e = 1 ? "activo" : "inactivo"}</SText> },
        {
          key: "-", label: "👉Presionar", width: 90, center: true, component: (obj) => {
            return <SView center width={50} height={30} card onPress={() => {


              var data = {
                key_usuario: obj.key_usuario,
                fecha_inicio: new SDate(captura_fecha_inicio).toString("yyyy-MM-dd hh:mm:ss"),
                fecha_fin: new SDate(captura_fecha_fin).toString("yyyy-MM-dd hh:mm:ss"),
                key_paquete: pajarito,
              }
              SSocket.send({
                component: "paquete_promo_usuario",
                type: "registro",
                key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
                data: data,
                estado: "cargando"
              })

            }}>
              <SText center>SUBIR</SText>
            </SView>
          }
        },

        {
          key: "-btnEditarFecha", label: "📅Edit Fecha", width: 70, center: true,
          component: (obj) => {
            // console.log("nada ",obj)
            return <SView onPress={() => {
              SPopup.open({ content: this.popupEditarFecha(obj), key: "CodigoSeguridad" });
            }}>
              <SIcon name={"Ajustes"} width={35} />
            </SView>
          }
        },
        // {
        //   key: "-btnEliminar", label: "🗑️Eliminar", width: 70, center: true,
        //   component: (item) => {
        //     return <SView onPress={() => {
        //       // SNavigation.navigate("DetalleCaja", { key: item })
        //     }}>
        //       <SIcon name={"Delete"} width={35} />
        //     </SView>
        //   }
        // },
        {
          key: "-a", label: "icono", width: 60, center: true,
          component: (item) => {
            return <SView  >
              <SIcon name={"LogoClean"} width={35} />
            </SView>
          }
        },
        {
          key: "-b", label: "icono", width: 60, center: true,
          component: (obj) => {
            return <SView onPress={() => {

              SSocket.sendPromise({
                component: "reporte",
                type: "execute_function",
                func: "_promo_usuario_borrar_fila",
                params: ["'" + obj.key + "'"],
                ...this.params
              }).then((resp) => {
                this.setState({ loading: false, data: resp.data1 });
                this.props.dispatch(resp);
              })
                .catch((e) => {
                  this.setState({ loading: false, error: e });
                });
            }} >
              <SIcon name={"NoDelete"} width={35} />
            </SView>
          }
        },
        {
          key: "-c", label: "icono", width: 60, center: true,
          component: (item) => {
            return <SView  >
              <SIcon name={"Reload"} width={35} />
            </SView>
          }
        },
        {
          key: "-d", label: "icono", width: 60, center: true,
          component: (item) => {
            return <SView onPress={() => {
             }} >
              <SIcon name={"Close"} width={35} />
            </SView>
          }
        },


      ]}
      data={this.state.data} />
  }
  render() {
    return (
      <SPage title={'Migrador de ventas'} disableScroll center>
        {this.renderExcelImport()}
        {this.renderTable()}
        <SLoad type='window' hidden={!this.state.loading} />
      </SPage>
    );
  }
}

const initStates = (state) => {
  return { state }
};
export default connect(initStates)(testAlvaro);
