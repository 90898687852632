import { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SForm, SHr, SImage, SInput, SLoad, SNavigation, SScrollView2, SText, STheme, SUuid, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Paquete from '../../../../Paquete';

class ConfirmarPaquete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_factura: true
    };
  }


  sendServer(data) {

    var clientes = this.props.data.usuariosData.map((obj, i) => {
      // if (!clientes) return ;
      var tsk = this.props.data.tasks[i];
      var data_pago = this.props.data.dataPagos[i];
      if (!data_pago) data_pago = {};
      return {
        key: obj.key,
        fecha_inicio: tsk.fecha.toString("yyyy-MM-dd"),
        fecha_fin: tsk.fecha.addDay(tsk.dias).toString("yyyy-MM-dd"),
        data: data_pago
      }
    });
    data.key = SUuid();
    // console.log("termina aqui ", data)
    // return;

    var object = {
      component: "paqueteVenta",
      type: "registro",
      estado: "cargando",
      key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
      data,
      clientes: clientes,
    }
    this.setState({ loading: true })

    console.log("sur ", object)

    SSocket.sendPromise(object, 1000 * 60 * 5).then(e => {
      this.setState({ loading: false })
      console.log("pariendo1 ", e)

      SNavigation.replace("EsperandoVenta", { key: e.data.key })
    }).catch(e => {
      this.setState({ loading: false })
      console.log("pariendo ", e)
      console.log("sur ", data)

      alert(e.error)
    })
    console.log("cotoca ", data)


  }

  getPaquete(paquete) {
    return <SView col={"xs-12"} center>
      <SView col={"xs-12"} flex style={{ alignItems: "flex-start" }}>
        <SText >PAQUETE</SText>
        {/* <SText >Verifica los datos del recibo!</SText> */}
      </SView>
      <SHr height={10} />
      <SView col={"xs-12"} row center backgroundColor={STheme.color.darkGray} style={{ borderRadius: 10, padding: 18 }}>
        <SView col={"xs-3"} row  >
          <SView style={{ width: 70, height: 70, justifyContent: "center", alignItems: "center", borderRadius: 15, overflow: "hidden" }}>
            <SImage enablePreview src={SSocket.api.root + "paquete/" + this.props.data.key_paquete + "?time=" + new Date().getTime()} width={"100%"} height={"100%"} style={{ resizeMode: 'cover' }} />
          </SView>
        </SView>
        <SView col={"xs-9"} height>
          <SText color={STheme.color.text} fontSize={18} style={{ textTransform: "uppercase" }}>{paquete?.descripcion}</SText>
          <SHr h={4} />
          <SView col={"xs-12"} flex>
            <SText color={STheme.color.text} fontSize={12} >{paquete?.observacion}</SText>
          </SView>
          <SHr h={4} />
          {/* <SView col={"xs-12"} height={50} row>
            <SView col={"xs-2.8"} center >
              <SText fontSize={14} >Días</SText>
              <SView row>
                <SIcon name='Pdia' width={35} />
                <SView width={15} />
                <SText fontSize={32} >{paquete?.dias}</SText>
              </SView>
            </SView>
            <SView style={{ borderWidth: 1, borderRightColor: STheme.color.secondary }} height width={2}></SView>
            <SView col={"xs-2.8"} center  >
              <SText fontSize={14} >Participantes</SText>
              <SView row>
                <SIcon name='Pdia' width={35} />
                <SView width={15} />
                <SText fontSize={32} >{paquete?.participantes}</SText>
              </SView>
            </SView>
            <SView style={{ borderWidth: 1, borderRightColor: STheme.color.secondary }} height width={2}></SView>
            <SView col={"xs-3.8"} center  >
              <SText fontSize={14} >Precio</SText>
              <SView row>
                <SIcon name='Pdia' width={35} />
                <SView width={15} />
                <SText fontSize={32} >{paquete?.precio} Bs</SText>
              </SView>
            </SView>
          </SView> */}
        </SView>
      </SView>
    </SView>
  }

  getDetalleVenta(paquete) {
    if (!paquete) return <SLoad />

    return <SView col={"xs-12"} center>
      <SView col={"xs-12"} flex style={{ alignItems: "flex-start" }}>
        <SText >DETALLE VENTA</SText>
      </SView>
      <SHr height={16} />
      <SView height={115} col={"xs-12"} row
        backgroundColor={STheme.color.darkGray}
        style={{ borderRadius: 10, padding: 18 }} >
        <SView col={"xs-6"}>
          <SText fontSize={14}>PRECIO UNITARIO</SText>
        </SView>
        <SView col={"xs-6"} flex style={{ alignItems: "flex-end" }}>
          <SText>{paquete?.precio}</SText>
        </SView>
        <SView height={2} col={"xs-12"} style={{ borderColor: STheme.color.gray + 99, borderBottomWidth: 1 }}></SView>
        <SView col={"xs-6"}>
          <SText>(-) DESCUENTO Bs</SText>
        </SView>
        <SView col={"xs-6"} flex style={{ alignItems: "flex-end" }}>
          <SText>{this.props.data?.descuento ?? 0}</SText>
        </SView>
        <SView height={2} col={"xs-12"} style={{ borderColor: STheme.color.gray + 99, borderBottomWidth: 1 }}></SView>
        <SView col={"xs-6"}>
          <SText>SUBTOTAL Bs.</SText>
        </SView>
        <SView col={"xs-6"} flex style={{ alignItems: "flex-end" }}>
          <SText>{(paquete?.precio - (this.props.data?.descuento ?? 0))}</SText>
        </SView>
        <SView height={2} col={"xs-12"} style={{ borderColor: STheme.color.gray + 99, borderBottomWidth: 1 }}></SView>
        <SView col={"xs-6"}>
          <SText>MONTO PAGADO</SText>
        </SView>
        <SView col={"xs-6"} flex style={{ alignItems: "flex-end" }}>
          <SText>{(paquete?.precio - (this.props.data?.descuento ?? 0))}</SText>
        </SView>
      </SView>



    </SView>
  }

  getCheckFactura() {
    let usuarios = this.props.data?.usuariosData[0];
    if (!usuarios) return;
    return <SView col={"xs-12"} center>
      <SView col={"xs-12"} flex style={{ alignItems: "flex-start" }}>
        <SText >Opciones</SText>
      </SView>
      <SHr height={10} />


      <SInput type={"checkBox"} label={"Factura"}
        value={this.state.is_factura}
        onChangeText={(e) => {
          // this._pipoca = e;
          if (e) {
            this.state.is_factura = true
            // this._factura = e;
            // console.log("con factura")
          } else {
            // console.log("NOoooo factura")
            // this.state._totalHoras--;
            // this._factura = e;
            this.state.is_factura = false
          }

          // this.state._sabado[obj.key] = e;
          this.setState({ ...this.state })
          // this.setState({ ...this.state })
        }}
      />
      {/* <SInput type={"checkBox"} label={"Recibo"}
        value={true}
        onChangeText={(e) => {
          if (e) {
            console.log("con Recibo")
          } else {
            console.log("NOoooo Recibo")
          }
        }}
      /> */}

    </SView>
  }
  getFormUsuario() {
    let usuarios = this.props.data?.usuariosData[0];
    if (!usuarios) return;
    return <SView col={"xs-12"} center>
      <SView col={"xs-12"} flex style={{ alignItems: "flex-start" }}>
        <SText >DATOS PARA LA FACTURA</SText>
      </SView>
      <SHr height={10} />
      <SView col={"xs-12"} row
        backgroundColor={STheme.color.darkGray}
        style={{ borderRadius: 10, padding: 18 }} >
        <SView col={"xs-12"}>
          <SForm
            ref={ref => this.form_factura = ref}

            inputProps={{
              // fontSize:5,
              col: "xs-12", customStyle: "calistenia",
            }}
            inputs={{
              "nombre_completo": { label: <SText color="white" >Nombre</SText>, defaultValue: (usuarios.Nombres + " " + usuarios.Apellidos) ?? "" },
              "telefono": { label: "Telefono", defaultValue: usuarios.Telefono ?? "" },
              "correo": { label: "Correo", defaultValue: usuarios.Correo ?? "", style: { borderColor: "orange" } },
              "nit": { label: "NIT",type: 'number', defaultValue: usuarios.Nit ?? "0", isRequired: true, style: { borderColor: "cyan", borderRadius: 8, } },
              "razon_social": { focus: true, label: "Razon social", defaultValue: usuarios.RazonSocial ?? "SN", isRequired: true, style: { borderColor: "cyan", borderRadius: 8, } },
            }}></SForm>
        </SView>
      </SView>
    </SView>
  }
  getPagar() {
    if (!this.paquete) { return <SView /> }
    var usrs = {};
    if (!this.props.data.usuariosData) { return <SView /> }

    this.props.data.usuariosData.map((obj) => {
      usrs[obj.key] = true;
    })


    return <SButtom props={{ type: "danger", variant: "default" }}
      loading={this.state.loading}

      style={{
        width: "100%",
        height: 60,
        borderRadius: 8,
        margin: 0,
      }}

      onPress={() => {
        // console.log("mira esta ",this._pipoca)
        if (this.state.loading) return;
        var IsValid = true;
        // var dataFinal = {};
        let datos_factura = {
          ...this.form_factura.getValues(),
          ...this.params,
        }
        datos_factura.correos = [datos_factura.correo];
        if ((datos_factura.nit).length < 4) {
          alert("El NIT debe tener menos de 5 dígitos.")
          return;
        }
        let data = {
          descripcion: "",
          key_paquete: this.paquete.key,
          monto: this.paquete.precio,
          nombre_paquete: this.paquete.descripcion,
          observacion: this.props.data.observacion,
          ...datos_factura,
          descuento: this.props.data.descuento,
          is_factura: this.state.is_factura,
        }
        // console.log("fdacturacion ", this.state.is_factura)
        // console.log("exito facturacion ", (datos_factura.nit).length);
        // console.log("exito facturacion ", data);
        this.sendServer(data)
      }}>CONFIRMAR PAGO</SButtom >
  }

  render() {
    var paquete = Paquete.Actions.getByKey(this.props.data.key_paquete, this.props);
    if (!paquete) return <SLoad />
    this.paquete = paquete;
    return (
      <SView props={{ col: "xs-12", variant: "center" }} style={{ flex: 1 }}>
        <SScrollView2 disableHorizontal>
          <SView props={{ col: "xs-12", variant: "center" }} style={{ flex: 1, }}>
            <SView props={{ col: "xs-11.5 md-8 xl-6", withoutFeedback: true }} style={{ borderRadius: 8, maxHeight: "100%", flex: 1 }}>
              <SView style={{ width: "100%", flex: 1, borderRadius: 10, overflow: "hidden", alignItems: "center", paddingBottom: 8 }}>
                <SView SView col={"xs-12 md-4 lg-4"} center>
                  {this.getPaquete(paquete)}
                  <SHr height={16} />
                  {this.getDetalleVenta(paquete)}
                  <SHr height={16} />
                  {this.getFormUsuario()}
                  <SHr height={16} />
                  {this.getCheckFactura()}
                  <SHr height={16} />
                  {this.getPagar()}
                  <SHr height={26} />
                </SView>
              </SView>
            </SView>
          </SView>
        </SScrollView2>
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(ConfirmarPaquete);