import { Component } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { ExportExcel, SButtom, SHr, SImage, SLoad, SNavigation, SOrdenador, SPage, SScrollView2, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import BarraSuperior from '../../../../Components/BarraSuperior';
import Buscador from '../../../../Components/Buscador';
import FloatButtom from '../../../../Components/FloatButtom';
import Model from '../../../../Model';
import { SSRolesPermisosValidate } from '../../../../SSRolesPermisos';
import Usuario from '../..';
import FloatButtomSyncUsuario from '../../../../Components/FloatButtomSyncUsuario';

var objFinal = {};
let tamaño_texto = 9;

class UsuarioPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        curPage: 1,
      }
    };

  }


  componentDidMount() {
    // if (this.props.state.usuarioReducer.usuarioLog) {
    //   var object = {
    //     service: "usuario",
    //     component: "usuario",
    //     version: "2.0",
    //     type: "getAll",
    //     estado: "cargando",
    //     cabecera: "registro_administrador",
    //     key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
    //   }
    //   SSocket.send(object);

    // }
    Model.usuario.Action.getAll({ force: true });

    const isMobile = window.innerWidth <= 768;
    tamaño_texto = isMobile ? 6.5 : 9;


  }
  pagination = (listaKeys) => {
    if (!listaKeys) {
      return [];
    }
    if (listaKeys.length <= 0) {
      return [];
    }
    var pageLimit = 50
    var tamanho = listaKeys.length;
    var nroBotones = Math.ceil(tamanho / pageLimit);
    if (this.state.pagination.curPage > nroBotones) {
      this.state.pagination.curPage = nroBotones;
    }
    var actual = pageLimit * (this.state.pagination.curPage - 1);
    return listaKeys.slice(0, actual + pageLimit);
  }
  getRecuperar(data, isRecuperar) {
    if (!isRecuperar) {
      return <View />
    }
    if (data.estado != 0) {
      return <View />
    }

    console.log("presiono ", data)
    console.log("presiono2 ", isRecuperar)
    return <SButtom
      props={{
        type: "danger",
        variant: "confirm"
      }}
      onPress={() => {
        Usuario.Actions.editar({
          ...data,
          estado: 1,
        }, this.props)
      }} >Recuperar</SButtom>
  }

  valido_CI(ci) {
    // valido si es null el parametro ci
    return (
      <Text style={{ color: (ci ?? "").length < 7 ? "red" : STheme.color.text }}>
        {"CI: " + (ci ?? "")}
      </Text>
    );
  }
  valido_Telefono(telefono) {
    // valido si es null el parametro telefono
    return (
      <Text
        style={{
          color:
            (telefono ?? "").length < 8 ||
              ((telefono ?? "").charAt(0) !== "7" &&
                (telefono ?? "").charAt(0) !== "6" &&
                (telefono ?? "").charAt(0) !== "+")
              ? "red"
              : STheme.color.text,
        }}
      >
        {"Telefono: " + (telefono ?? "")}
      </Text>
    );
  }
  valido_Correo(correo) {
    // valido si es null el parametro correo
    return (
      <Text style={{ color: (correo ?? "").length < 12 ? "red" : STheme.color.text }}>
        {"Correo: " + (correo ?? "")}
      </Text>
    );
  }
  valido_Cumpleaños(Cumpleaños) {
    // valido si es null el parametro Cumpleaños
    return (
      <Text style={{ color: (Cumpleaños ?? "").length < 10 ? "red" : STheme.color.text }}>
        {"Cumpleaños: " + (Cumpleaños ?? "")}
      </Text>
    );
  }

  valido_Facturacion(nombre) {
    if (!nombre) return;
    // valido si es null el parametro empresa
    return (<>
      <SView border={"yellow"} style={{ padding: 1, position: "absolute", top: -6 }}>
        <Text style={{ color: STheme.color.text }}>
          {"FACTURACIÓN"}
        </Text>
      </SView>
      <SHr></SHr>
    </>
    );
  }

  valido_RazonSocial(nombre) {
    if (!nombre) return;
    // valido si es null el parametro empresa
    return (
      <Text style={{ color: (nombre ?? "").length < 2 ? "red" : STheme.color.text }}>
        {"Razón Social: " + (nombre ?? "")}
      </Text>
    );
  }

  valido_Nit(nit) {
    if (!nit) return;
    // valido si es null el parametro empresa
    return (
      <Text style={{ color: (nit ?? "").length < 2 ? "red" : STheme.color.text }}>
        {"Nit: " + (nit ?? "")}
      </Text>
    );
  }

  valido_Cumpleaños2(Cumpleaños) {
    var fecha = Cumpleaños;
    var fechaObj = new Date(fecha);
    var mes = fechaObj.getMonth() + 1;

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;

    var mensaje = "";
    if (mes === mesActual) {
      mensaje = "Cumpleañero 🥳📆 " + (fecha ?? "");
    } else {
      mensaje = "Fecha nacimiento: " + (fecha ?? "");
    }
    const textColor = (Cumpleaños === null ? "red" : (mes === mesActual ? "green" : STheme.color.text));
    return <Text style={{ color: textColor }}>{mensaje}</Text>;
  }

  valido_Zona(zona) {
    // if (!zona) return;
    // valido si es null el parametro zona
    return (
      <Text style={{ color: (zona ?? "").length < 3 ? "red" : STheme.color.text }}>
        {"Zona: " + (zona ?? "")}
      </Text>
    );
  }

  valido_Direccion(Direccion) {
    // if (!Direccion) return;
    // valido si es null el parametro zona
    return (
      <Text style={{ color: (Direccion ?? "").length < 5 ? "red" : STheme.color.text }}>
        {"Dirección: " + (Direccion ?? "")}
      </Text>
    );
  }

  valido_Empresa(empresa) {
    if (!empresa) return;
    // valido si es null el parametro empresa
    return (
      <Text style={{ color: (empresa ?? "").length < 3 ? "red" : STheme.color.text }}>
        {"Empresa: " + (empresa ?? "")}
      </Text>
    );
  }
  valido_Cargo(cargo) {
    if (!cargo) return;
    // valido si es null el parametro cargo
    return (
      <Text style={{ color: (cargo ?? "").length < 3 ? "red" : STheme.color.text }}>
        {"Cargo: " + (cargo ?? "")}
      </Text>
    );
  }
  valido_Referencia(referencia) {
    if (!referencia) return;
    // valido si es null el parametro cargo
    return (
      <Text style={{ color: (referencia ?? "").length < 5 ? "red" : STheme.color.text }}>
        {"Referencia: " + (referencia ?? "")}
      </Text>
    );
  }

  getData(llaverito) {
    // console.log("entro ");

    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "reporte",
      type: "execute_function",
      func: "_get_sucursal_usuario",
      params: ["'" + llaverito + "'"],
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
        console.log("todo un exito ", resp);
      })
      .catch((e) => {
        console.error("error ", e);
        this.setState({ loading: false, error: e });
      });
  }

  // valido_veces(key_usuario) {
  //   // var this.getData(key_usuario)
  //   return <>
  //     <Text center style={{
  //       color: STheme.color.text, position: "absolute", right: 0,
  //     }}>{"veces (" + numero + ")"}</Text>
  //     {/* <SHr></SHr> */}
  //     <Text center style={{
  //       color: STheme.color.text, position: "absolute", right: 0, top: 20
  //     }}>{sucursal}</Text>
  //   </>
  // }


  getExportExcel() {

    // var isRecuperar = SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "recuperar_eliminado" });


    if (!SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "exportar_excel" })) {
      return null;
    }

    return <SView col={"xs-12"} center>
      {/* tarea10 ✅ ✅ ✅ */}
      <ExportExcel
        header={[
          { key: "indice", label: "n", width: 40 },
          //  { key: "key_usuario", label: "key", width: 220 },
          { key: "nombres", label: "Nombres", width: 200 },

          { key: "ci", label: "Documento", width: 60 },
          { key: "telefono", label: "Telefono", width: 90 },
          { key: "correo", label: "Correo", width: 170 },

          { key: "cumpleaños", label: "Cumpleaños", width: 75 },
          { key: "zona", label: "Zona", width: 150 },
          { key: "direccion", label: "Dirección", width: 170 },

          { key: "empresa", label: "Empresa", width: 150 },
          { key: "cargo", label: "Cargo", width: 150 },
          { key: "referencia", label: "Referencia", width: 100 },
        ]}
        getDataProcesada={() => {
          var daFinal = {};
          // const ingreso = 0, egreso = 0, traspaso = 0;
          var total = { ingreso: 0, egreso: 0, traspaso: 10 }

          Object.values(objFinal).map((obj, i) => {
            if (obj.estado == 0) return;
            var toInsert = {
              indice: i + 1,
              key_usuario: obj?.key,
              nombres: obj?.Nombres + " " + obj?.Apellidos,
              // ci: obj?.CI,
              // correo: obj?.Correo,
              // cumpleaños: obj["Fecha nacimiento"],
              // telefono: obj?.Telefono,
              ci: obj?.CI,
              telefono: obj?.Telefono,
              correo: obj?.Correo,

              cumpleaños: obj["Fecha nacimiento"],
              zona: obj?.Zona,
              direccion: obj?.Direccion,

              empresa: obj?.Empresa,
              cargo: obj?.Cargo,
              referencia: obj?.Referencia,
            }
            daFinal[i] = toInsert
          })
          return daFinal
        }}
      />
    </SView>
  }



  render() {

    const getLista = () => {
      var data = Model.usuario.Action.getAll();
      if (!data) return <SLoad />
      if (!this.state.buscador) {
        return <View />
      }
      Object.keys(data).map((key) => {
        objFinal[key] = data[key];
      });



      var isRecuperar = SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "recuperar_eliminado" });
      return this.pagination(
        new SOrdenador([
          { key: "Peso", order: "desc", peso: 4 },
          { key: "Nombres", order: "asc", peso: 2 },
          { key: "Apellidos", order: "asc", peso: 1 },
        ]).ordernarObject(
          this.state.buscador.buscar(objFinal)
        )
      ).map((key) => {
        var obj = data[key];
        return <TouchableOpacity
          style={{
            width: "90%",
            maxWidth: 600,
            height: 65,
            margin: 4,
            borderRadius: 10,
            backgroundColor: STheme.color.card
          }} onPress={() => {
            SNavigation.navigate("registro", { key: key })
          }}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View style={{
              flexDirection: "row",
              height: "100%",
              width: "100%",
              alignItems: "center"
            }}>
              <View style={{
                width: 40,
                height: 40,
                marginRight: 8,
                marginLeft: 8,
                justifyContent: "center",
                alignItems: "center",
         
                backgroundColor: STheme.color.card,
                borderRadius: 100,
                overflow: "hidden"
              }}>
                <SImage src={SSocket.api.root + "usuario/" + key + `?date=${new Date().getTime()}`} />

              </View>
              <View style={{
                flex: 1,
                justifyContent: "center"
              }}>
                <Text style={{
                  fontSize: 14,
                  color: STheme.color.text,
                  textDecorationLine: (obj.estado == 0 ? "line-through" : "none"),
                  // fontWeight: "bold"
                }}>{obj["Nombres"] + " " + obj["Apellidos"]}</Text>
                <SView col={"xs-12"} row >
                  <SView col={"xs-4"} border={"transparent"} >
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}   >
                      {this.valido_CI(obj?.CI)}
                    </Text>

                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}   >
                      {this.valido_Telefono(obj?.Telefono)}
                    </Text>
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}   >
                      {this.valido_Correo(obj?.Correo)}
                    </Text>
                  </SView>

                  {/* <SView col={"xs-4"} border={"transparent"} >
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Cumpleaños(obj?.["Fecha nacimiento"])}
                    </Text>

                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Zona(obj?.Zona)}
                    </Text>
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Direccion(obj?.Direccion)}
                    </Text>
                  </SView> */}

                  <SView col={"xs-4"} border={"transparent"} >
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Facturacion(obj?.RazonSocial)}
                    </Text>

                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_RazonSocial(obj?.RazonSocial)}
                    </Text>
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Nit(obj?.Nit)}
                    </Text>
                  </SView>


                  <SView col={"xs-4"} border={"transparent"} >
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Empresa(obj?.Empresa)}
                    </Text>
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Cargo(obj?.Cargo)}
                    </Text>
                    <Text style={{
                      fontSize: tamaño_texto,
                      color: STheme.color.text,
                      textDecorationLine: obj.estado == 0 ? "line-through" : "none"
                    }}>
                      {this.valido_Referencia(obj?.Referencia)}
                    </Text>
                  </SView>
                </SView>
                {/* <SView row col={"xs-12"}>
                  {this.valido_Telefono(obj?.Telefono)}
                  <SView width={10} />
                  {this.valido_Correo(obj?.Correo)}
                </SView> */}
                {/* }}>{obj["Nombres"] + " " + obj["Apellidos"]} {this.valido_Telefono(obj?.Telefono)} {this.valido_Correo(obj?.Correo)} {this.valido_veces(key)}  </Text> */}
              </View>
              {this.getRecuperar(obj, isRecuperar)}
            </View>
          </View>
        </TouchableOpacity>
      })
    }
    return (
      <SPage hidden disableScroll>
        <BarraSuperior title={"Usuarios"} navigation={this.props.navigation} goBack={() => {
          SNavigation.goBack();
        }} />
        <Buscador placeholder={"Buscar por CI, Nombres, Apellidos, Correo o Telefono."} ref={(ref) => {
          if (!this.state.buscador) this.setState({ buscador: ref });
        }} repaint={() => { this.setState({ ...this.state }) }}
          eliminados={SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "ver_eliminados" })}
        />
        <View style={{
          flex: 1,
          width: "100%",
        }}>
          <SScrollView2
            disableHorizontal
            onScroll={(evt) => {
              var evn = evt.nativeEvent;
              var posy = evn.contentOffset.y + evn.layoutMeasurement.height;
              var heigth = evn.contentSize.height;
              if (heigth - posy <= 0) {
                this.state.pagination.curPage += 1;
                this.setState({ ...this.state })
              }
            }}
          >



            {this.getExportExcel()}

            <SView col={"xs-12"} center>
              {getLista()}

            </SView>
          </SScrollView2>
          <FloatButtomSyncUsuario style={{   bottom: 90,  }}
              onPress={() => {
                Model.usuario.Action.CLEAR_STORAGE()
              }}
            />
          <FloatButtom esconder={!SSRolesPermisosValidate({ page: "UsuarioPage", permiso: "crear" })} onPress={() => {
            SNavigation.navigate("registro")
          }} />
        </View>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(UsuarioPage);